<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2015-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            icon
            @click="showChart = !showChart"
          >
            <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-areaspline' }}</v-icon>
          </v-btn>
          <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-slide-y-transition>
        <v-card-text v-show="showChart">
          <chart
            :options="chartOptions"
            :series="series"
            type="area"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="31"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-right">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b10 }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ props.item.b39 }}
                </td>
              </template>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ props.item.b49 }}
                </td>
              </template>
              <td class="text-xs-center info--text">
                {{ props.item.b59 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b59db }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89db }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b54db }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ props.item.b149 }}
                </td>
              </template>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ props.item.nu }}
                </td>
              </template>
              <td class="text-xs-center info--text">
                {{ props.item.b150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b39_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.tbg50 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b54db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b59db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b89db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.b150_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.onefamMain }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.onefamSub }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.siswaPlusMain }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.siswaPlusSub }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.mysiswa }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wifiPlus }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wifiPremium }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wawa18 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.wawa35 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.unl35 }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ props.item.perpaduan }}
                </td>
              </template>
              <td class="text-xs-center">
                {{ props.item.black }}
              </td>
              <td class="text-xs-center">
                {{ props.item.prepaid }}
              </td>
              <td class="text-xs-center">
                {{ props.item.tbg }}
              </td>
              <td class="text-xs-center">
                {{ props.item.siswaPrepaid }}
              </td>
              <td class="text-xs-center">
                {{ props.item.aCare }}
              </td>
              <td class="text-xs-center">
                {{ props.item.oneX }}
              </td>
              <td class="text-xs-center">
                {{ props.item.br }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.total }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
&nbsp;
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b10 }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ totals.b39 }}
                </td>
              </template>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ totals.b49 }}
                </td>
              </template>
              <td class="text-xs-center info--text">
                {{ totals.b59 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b59db }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89db }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b54db }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ totals.b149 }}
                </td>
              </template>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ totals.nu }}
                </td>
              </template>
              <td class="text-xs-center info--text">
                {{ totals.b150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b39_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.tbg50 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b54db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b59db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b89db_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.b150_fc }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.onefamMain }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.onefamSub }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.siswaPlusMain }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.siswaPlusSub }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.mysiswa }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wifiPlus }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wifiPremium }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wawa18 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.wawa35 }}
              </td>
              <td class="text-xs-center info--text">
                {{ totals.unl35 }}
              </td>
              <template v-if="monthOf < '2024-08'">
                <td class="text-xs-center info--text">
                  {{ totals.perpaduan }}
                </td>
              </template>
              <td class="text-xs-center info">
                {{ totals.black }}
              </td>
              <td class="text-xs-center info">
                {{ totals.prepaid }}
              </td>
              <td class="text-xs-center info">
                {{ totals.tbg }}
              </td>
              <td class="text-xs-center info">
                {{ totals.siswaPrepaid }}
              </td>
              <td class="text-xs-center info">
                {{ totals.aCare }}
              </td>
              <td class="text-xs-center info">
                {{ totals.oneX }}
              </td>
              <td class="text-xs-center info">
                {{ totals.br }}
              </td>
              <td class="text-xs-center info">
                {{ totals.total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import apexcharts from '@/mixins/apexcharts'

const reportTitle = 'Daily Activation'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
    Chart: () => import(/* webpackChunkName: "chart" */ '@/components/Chart'),
  },
  mixins: [apexcharts],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily Activation', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      // headers: [
      //   {
      //     align: 'right',
      //     sortable: false,
      //     text: 'Date',
      //     value: 'date',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B10',
      //     value: 'b10',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B39',
      //     value: 'b39',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B49',
      //     value: 'b49',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B59(E)/B39+20',
      //     value: 'b59',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B59DB',
      //     value: 'b59db',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B89/B39+50',
      //     value: 'b89',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B89DB',
      //     value: 'b89db',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B54DB',
      //     value: 'b54db',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B149',
      //     value: 'b149',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'NU',
      //     value: 'nu',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B150',
      //     value: 'b150',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B39_FC',
      //     value: 'b39_fc',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'TBG50',
      //     value: 'tbg50',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B54DB_FC',
      //     value: 'b54db_fc',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B59DB_FC',
      //     value: 'b59db_fc',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B89DB_FC',
      //     value: 'b89db_fc',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'B150_FC',
      //     value: 'b150_fc',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'OneFamily Main',
      //     value: 'onefamMain',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'OneFamily Sub',
      //     value: 'onefamSub',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'SiswaPlus Main',
      //     value: 'siswaPlusMain',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'SiswaPlus Sub',
      //     value: 'siswaPlusSub',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Mysiswa',
      //     value: 'mysiswa',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Wifi Plus',
      //     value: 'wifiPlus',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Wifi Premium',
      //     value: 'wifiPremium',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'WAWA18',
      //     value: 'wawa18',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'WAWA35',
      //     value: 'wawa35',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'UNL35',
      //     value: 'unl35',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Perpaduan',
      //     value: 'perpaduan',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Black',
      //     value: 'black',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Prepaid',
      //     value: 'prepaid',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'TBG Prepaid',
      //     value: 'tbg',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Mysiswa Prepaid',
      //     value: 'siswaPrepaid',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'BR',
      //     value: 'br',
      //   },
      //   {
      //     align: 'center',
      //     sortable: false,
      //     text: 'Total',
      //     value: 'total',
      //   },
      // ],
      items: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    headers: function () {
      const headers = [
        {
          align: 'right',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'center',
          sortable: false,
          text: 'B10',
          value: 'b10',
        },
      ]
      this.monthOf < '2024-08' && headers.push({
        align: 'center',
        sortable: false,
        text: 'B39',
        value: 'b39',
      })
      this.monthOf < '2024-08' && headers.push({
        align: 'center',
        sortable: false,
        text: 'B49',
        value: 'b49',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59(E)/B39+20',
        value: 'b59',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59DB',
        value: 'b59db',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89/B39+50',
        value: 'b89',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89DB',
        value: 'b89db',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B54DB',
        value: 'b54db',
      })
      this.monthOf < '2024-08' && headers.push({
        align: 'center',
        sortable: false,
        text: 'B149',
        value: 'b149',
      })
      this.monthOf < '2024-08' && headers.push({
        align: 'center',
        sortable: false,
        text: 'NU',
        value: 'nu',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150',
        value: 'b150',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B39_FC',
        value: 'b39_fc',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'TBG50',
        value: 'tbg50',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B54DB_FC',
        value: 'b54db_fc',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B59DB_FC',
        value: 'b59db_fc',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B89DB_FC',
        value: 'b89db_fc',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'B150_FC',
        value: 'b150_fc',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'OneFamily Main',
        value: 'onefamMain',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'OneFamily Sub',
        value: 'onefamSub',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'SiswaPlus Main',
        value: 'siswaPlusMain',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'SiswaPlus Sub',
        value: 'siswaPlusSub',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Mysiswa',
        value: 'mysiswa',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Wifi Plus',
        value: 'wifiPlus',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Wifi Premium',
        value: 'wifiPremium',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'WAWA18',
        value: 'wawa18',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'WAWA35',
        value: 'wawa35',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'UNL35',
        value: 'unl35',
      })
      this.monthOf < '2024-08' && headers.push({
        align: 'center',
        sortable: false,
        text: 'Perpaduan',
        value: 'perpaduan',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Black',
        value: 'black',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Prepaid',
        value: 'prepaid',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'TBG Prepaid',
        value: 'tbg',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Mysiswa Prepaid',
        value: 'siswaPrepaid',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Acare',
        value: 'aCare',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'ONE-X',
        value: 'oneX',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'BR',
        value: 'br',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'Total',
        value: 'total',
      })

      return headers
    },
    series: function () {
      const series = [
        { name: 'Prepaid', data: [] },
        { name: 'Black', data: [] },
      ]

      for (let i = 0; i < this.filteredItems.length; i++) {
        const timestamp = DateTime.fromSQL(this.filteredItems[i].date).toMillis()
        series[0].data.push([timestamp, this.filteredItems[i].prepaid])
        series[1].data.push([timestamp, this.filteredItems[i].black])
      }

      return series
    },
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          date: this.onexox[i].date,
          prepaid: this.onexox[i].prepaid + (this.xox[i] ? this.xox[i].prepaid : 0),
          black: this.onexox[i].black + (this.xox[i] ? this.xox[i].black : 0),
          tbg: this.onexox[i].tbg + (this.xox[i] ? this.xox[i].tbg : 0),
          b10: this.onexox[i].b10 + (this.xox[i] ? this.xox[i].b10 : 0),
          b39: this.onexox[i].b39 + (this.xox[i] ? this.xox[i].b39 : 0),
          b49: this.onexox[i].b49 + (this.xox[i] ? this.xox[i].b49 : 0),
          b59: this.onexox[i].b59 + (this.xox[i] ? this.xox[i].b59 : 0),
          b59db: this.onexox[i].b59db + (this.xox[i] ? this.xox[i].b59db : 0),
          b89: this.onexox[i].b89 + (this.xox[i] ? this.xox[i].b89 : 0),
          b89db: this.onexox[i].b89db + (this.xox[i] ? this.xox[i].b89db : 0),
          b54db: this.onexox[i].b54db + (this.xox[i] ? this.xox[i].b54db : 0),
          b149: this.onexox[i].b149 + (this.xox[i] ? this.xox[i].b149 : 0),
          nu: this.onexox[i].nu + (this.xox[i] ? this.xox[i].nu : 0),
          b150: this.onexox[i].b150 + (this.xox[i] ? this.xox[i].b150 : 0),
          b39_fc: this.onexox[i].b39_fc + (this.xox[i] ? this.xox[i].b39_fc : 0),
          tbg50: this.onexox[i].tbg50 + (this.xox[i] ? this.xox[i].tbg50 : 0),
          b54db_fc: this.onexox[i].b54db_fc + (this.xox[i] ? this.xox[i].b54db_fc : 0),
          b59db_fc: this.onexox[i].b59db_fc + (this.xox[i] ? this.xox[i].b59db_fc : 0),
          b89db_fc: this.onexox[i].b89db_fc + (this.xox[i] ? this.xox[i].b89db_fc : 0),
          b150_fc: this.onexox[i].b150_fc + (this.xox[i] ? this.xox[i].b150_fc : 0),
          onefamMain: this.onexox[i].onefamMain + (this.xox[i] ? this.xox[i].onefamMain : 0),
          onefamSub: this.onexox[i].onefamSub + (this.xox[i] ? this.xox[i].onefamSub : 0),
          mysiswa: this.onexox[i].mysiswa + (this.xox[i] ? this.xox[i].mysiswa : 0),
          wawa18: this.onexox[i].wawa18 + (this.xox[i] ? this.xox[i].wawa18 : 0),
          wawa35: this.onexox[i].wawa35 + (this.xox[i] ? this.xox[i].wawa35 : 0),
          unl35: this.onexox[i].unl35 + (this.xox[i] ? this.xox[i].unl35 : 0),
          perpaduan: this.onexox[i].perpaduan + (this.xox[i] ? this.xox[i].perpaduan : 0),
          siswaPlusMain: this.onexox[i].siswaPlusMain + (this.xox[i] ? this.xox[i].siswaPlusMain : 0),
          siswaPlusSub: this.onexox[i].siswaPlusSub + (this.xox[i] ? this.xox[i].siswaPlusSub : 0),
          wifiPlus: this.onexox[i].wifiPlus + (this.xox[i] ? this.xox[i].wifiPlus : 0),
          wifiPremium: this.onexox[i].wifiPremium + (this.xox[i] ? this.xox[i].wifiPremium : 0),
          siswaPrepaid: this.onexox[i].siswaPrepaid + (this.xox[i] ? this.xox[i].siswaPrepaid : 0),
          aCare: this.onexox[i].aCare + (this.xox[i] ? this.xox[i].aCare : 0),
          oneX: this.onexox[i].oneX + (this.xox[i] ? this.xox[i].oneX : 0),
          br: this.onexox[i].br + (this.xox[i] ? this.xox[i].br : 0),
          total: this.onexox[i].total + (this.xox[i] ? this.xox[i].total : 0),
        })
      }

      return all
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      for (const item of items) {
        const date = new Date(DateTime.fromSQL(item.date).toMillis())
        item.day = date.getDate()
      }

      return items
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        prepaid: 0,
        black: 0,
        tbg: 0,
        b10: 0,
        b39: 0,
        b49: 0,
        b59: 0,
        b59db: 0,
        b89: 0,
        b89db: 0,
        b54db: 0,
        b149: 0,
        nu: 0,
        b150: 0,
        b39_fc: 0,
        tbg50: 0,
        b54db_fc: 0,
        b59db_fc: 0,
        b89db_fc: 0,
        b150_fc: 0,
        onefamMain: 0,
        onefamSub: 0,
        mysiswa: 0,
        wawa18: 0,
        wawa35: 0,
        unl35: 0,
        perpaduan: 0,
        siswaPlusMain: 0,
        siswaPlusSub: 0,
        wifiPlus: 0,
        wifiPremium: 0,
        siswaPrepaid: 0,
        aCare: 0,
        oneX: 0,
        br: 0,
        total: 0,
      }

      for (const item of items) {
        totals.prepaid += item.prepaid
        totals.black += item.black
        totals.tbg += item.tbg
        totals.b10 += item.b10
        totals.b39 += item.b39
        totals.b49 += item.b49
        totals.b59 += item.b59
        totals.b59db += item.b59db
        totals.b89 += item.b89
        totals.b89db += item.b89db
        totals.b54db += item.b54db
        totals.b149 += item.b149
        totals.nu += item.nu
        totals.b150 += item.b150
        totals.b39_fc += item.b39_fc
        totals.tbg50 += item.tbg50
        totals.b54db_fc += item.b54db_fc
        totals.b59db_fc += item.b59db_fc
        totals.b89db_fc += item.b89db_fc
        totals.b150_fc += item.b150_fc
        totals.onefamMain += item.onefamMain
        totals.onefamSub += item.onefamSub
        totals.mysiswa += item.mysiswa
        totals.wawa18 += item.wawa18
        totals.wawa35 += item.wawa35
        totals.unl35 += item.unl35
        totals.perpaduan += item.perpaduan
        totals.siswaPlusMain += item.siswaPlusMain
        totals.siswaPlusSub += item.siswaPlusSub
        totals.wifiPlus += item.wifiPlus
        totals.wifiPremium += item.wifiPremium
        totals.siswaPrepaid += item.siswaPrepaid
        totals.aCare += item.aCare
        totals.oneX += item.oneX
        totals.br += item.br
        totals.total += item.total
      }

      return totals
    },
  },
  watch: {
    monthOf: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getActivationsByMonth.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
